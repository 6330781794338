import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SimpleMap from "../components/map"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import AboutProperty from "../components/about-property"
import PropertyHeaderDetail from "../components/property-header-detail"
import Details from "../components/details";
import VirtualTour from "../components/virtual-tour";

const RentalListing = ({ data, location }) => {
  const post = data.listingRental
  const center = {
    lat: post ? post.Latitude : null,
    lng: post ? post.Longitude : null,
  }

  return (
    <Layout>
      {post && (
        <>
          <Seo location={location} title={post.UnparsedAddress} />
          <div className="cha-pd-container">
            <PropertyHeaderDetail property={post} />
            <Carousel
              style={{
                height: `100vh`,
                width: `100vw`,
                display: `flex`,
                justifyContent: `flex-end`,
                alignItems: `flex-end`,
                color: `#fff`,
              }}
              showArrows={true}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              autoPlay={true}
              stopOnHover={false}
              interval={50000}
              transitionTime={500}
              preventMovementUntilSwipeScrollTolerance={true}
              swipeScrollTolerance={100}
            >
              {post.Media && post.Media.length
                ? post.Media.map(item => {
                  const media = item

                  return (
                    <img
                      style={{
                        height: `100vh`,
                        width: `100vw`,
                        objectFit: `cover`,
                      }}
                      alt={media.ShortDescription ? media.ShortDescription : ""}
                      src={media.MediaURL.replace("WIDTH", 1800).replace(
                        "HEIGHT",
                        1800 / 1.5
                      )}
                    />
                  )
                })
                : null}
            </Carousel>
            <AboutProperty
              property={post}
              agent={data.listAgent}
              coListAgent={data.coListAgent}
              additionalAgents={data.additionalListAgent?.nodes}
              contactReference={"rental_listing"}
              courtesyOf={`Rental Courtesy of: ${post.ListOfficeName}`}
            />
            <VirtualTour
              exclusive={true}
              tourUrl={post.VirtualTourURLUnbranded}
              unparsedAddress={post.UnparsedAddress}
            />
            <Details property={post} exclusive={true} />
            <div
              style={{
                width: `100%`,
                display: `flex`,
                flexDirection: `column`,
                alignItems: `center`,
                backgroundColor: `#fff`,
                color: `#000`,
              }}
            >
              <div style={{ padding: `4rem 3rem`, fontSize: `4rem` }}>
                Location
              </div>
              <div
                style={{
                  paddingBottom: `4rem`,
                  width: `100%`,
                  height: `100vh`,
                  display: `flex`,
                  justifyContent: `center`,
                  alignItems: `center`,
                }}
              >
                <SimpleMap
                  zoom={14}
                  center={center}
                  address={post.StreetNumber + " " + post.StreetName}
                ></SimpleMap>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($mlsId: String!, $ListAgentMlsId: String, $CoListAgentMlsId: String) {
    listingRental(ListingId: { eq: $mlsId }) {
      ListingId
      VirtualTourURLUnbranded
      ListAgentMlsId
      UnparsedAddress
      BathroomsTotalInteger
      BedroomsTotal
      City
      ListOfficeMlsId
      ListPrice
      LivingArea
      PrefferedPhoto
      PropertyType
      Media {
        MediaURL
        ShortDescription
      }
      Latitude
      Longitude
      StreetNumber
      StreetName
      PublicRemarks
      ArchitecturalStyle
      AssociationAmenities
      AttachedGarageYN
      BathroomsFull
      BathroomsHalf
      BathroomsThreeQuarter
      BuildingAreaTotal
      CountyOrParish
      FireplaceFeatures
      Heating
      HeatingYN
      LaundryFeatures
      LotSizeArea
      LotSizeSquareFeet
      LotSizeUnits
      MLSAreaMajor
      MLSAreaMinor
      ParkingFeatures
      PostalCode
      PropertyCondition
      Roof
      StateOrProvince
      YearBuilt
      CustomFields {
        title
        rows {
          text
          value
        }
      }
      StandardStatus
      ListOfficeName
    }

    listAgent: aspenSnowmassAgent(MemberMlsId: {eq: $ListAgentMlsId}) {
      MemberEmail
      MemberFullName
      MemberOfficePhone
      MemberMobilePhone
      Media
      photo {
        url
      }
      lastName
      firstName
    }

    coListAgent: aspenSnowmassAgent(MemberMlsId: {eq: $CoListAgentMlsId}) {
      MemberEmail
      MemberFullName
      MemberOfficePhone
      MemberMobilePhone
      Media
      photo {
        url
      }
      lastName
      firstName
    }
    
    additionalListAgent: allAspenSnowmassAgent(filter: {additionalMLSID: {in: [$ListAgentMlsId, $CoListAgentMlsId]}}) {
      nodes {
        MemberEmail
        MemberFullName
        MemberOfficePhone
        MemberMobilePhone
        Media
        photo {
          url
        }
        lastName
        firstName
      }      
    }      
  }
`
export default RentalListing;
